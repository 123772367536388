import { MEDIA_QUERY_MEDIUM, MEDIA_QUERY_SMALL_MAX } from "./variables";
import styled from "styled-components";


const SubMenuStyles = styled.nav`

  a {
    padding: 0 4rem;
    color: var(--themeColour);
    text-underline-offset: var(--underlineOffset);
    &[aria-current] {
      color: var(--black);
      text-decoration: none;
      pointer-events: none;
    }
  }

  @media (max-width: ${MEDIA_QUERY_SMALL_MAX}) {

    display: inline-flex;
    overflow-y: hidden;
    overflow-x: scroll;

    svg {
      display: none;
    }

    a {
      white-space: nowrap;
      padding: 1rem 2rem;
      position: relative;
      &:after {
        content: "";
        height: 13px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        background-color: var(--black);
      }

      &:last-child {
        &:after {
          content: none;
        }
      }

    }


  }

  @media (min-width: ${MEDIA_QUERY_MEDIUM}) {

    float: right;
    margin: 0 0 2rem 2rem;
    border: 2px solid var(--themeColour);
    padding: 2rem 0;
    border-radius: var(--borderRadius);
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.044);
    a {
      display: flex;
      align-items: center;
      font-size: 2rem;
      transition: 0.1s;
      text-underline-offset: var(--underlineOffset);
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &:hover {
        svg {
          transform: translateX(5px);
        }
      }
      svg {
        width: 26px;
        height: 26px; 
        transition: transform 0.3s;
      }
      &[aria-current] {
        svg {
          visibility: hidden;
        }
      }
    }

  }

`;

export default SubMenuStyles;
