import React, { useRef } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import getPageSlug from "../utils/getPageSlug";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import SubMenuStyles from "../styles/SubMenuStyles";
import HorizontalScrollContainer from "./HorizontalScrollContainer";
import orderPages from "../utils/orderPages";

const SubMenu = ({ pages, currentPageId }) => {

  if (!pages?.length) {

    return <></>;
  
  }

  const activeElement = useRef(null);
  const menuItems = useRef(null);

  const subPages = orderPages(pages);

  return (
    <HorizontalScrollContainer 
      menuItems={menuItems}
      activeElement={activeElement}
      repositionOnLoad={pages.findIndex(page => page._id === currentPageId) >= 0}
    >

      <SubMenuStyles ref={menuItems}>
        <Link draggable="false"
          to={getPageSlug(pages[0].parentPage)}
          title={pages[0].parentPage.name}
          ref={currentPageId === pages[0].parentPage._id ? activeElement : null }
        >
          {pages[0].parentPage.name}<BsArrowRightShort />
        </Link>
        {subPages.map(page => (
          <Link 
            draggable="false"
            to={getPageSlug(page)} 
            key={`subpage-${page._id}`}
            title={page.name}
            ref={currentPageId === page._id ? activeElement : null }
          >
            {page.name}<BsArrowRightShort />
          </Link>
        ))}
      </SubMenuStyles>

    </HorizontalScrollContainer>
  );

};


export default SubMenu;
