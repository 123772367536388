import { useState } from "react";

const useForm = defaults => {

  const initialValues = { ...defaults };
  const [ values, setValues ] = useState(defaults);
  
  const updateValue = e => {

    // check if number
    let { value } = e.target;

    if (e.target.type === "number") {

      value = parseInt(value);
    
    }
    setValues({
      ...values,
      [e.target.name]: value
    });
  
  };

  const resetAllValues = () => {

    setValues(initialValues);

  };

  return { values, updateValue, resetAllValues };

};

export default useForm;
