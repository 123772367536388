import React, { useEffect, useRef, useState } from "react";
import hasOverflow from "../utils/hasOverflow";
import { MEDIA_QUERY_SMALL_MAX } from "../styles/variables";
import PropTypes from "prop-types";
import screenResolution from "../utils/screenResolution";
import styled from "styled-components";

const ScrollWrapper = styled.aside`

  @media (max-width: ${MEDIA_QUERY_SMALL_MAX}) {

    margin: 2rem -2rem;
    position: relative;

    &:before, &:after {
      content: "";
      position: absolute;
      height: 100%;
      opacity: 0;
      pointer-events: none;
      top: 0;
      transition: opacity 0.3s;
      width: 8rem;
      z-index: 1;
    }

    &:before {
      background: linear-gradient(to right, var(--white) 0%, rgba(255,255,255, 0.01) 100%);
      left: 0;
    }

    &:after {
      background: linear-gradient(to right, rgba(255,255,255, 0.01) 0%, var(--white) 100%);
      right: 0;
    }

    &.grad-left {
      &:before {
        opacity: 1;
      }
    }
    &.grad-right {
      &:after {
        opacity: 1;
      }
    }

    .wrapper {

      overflow: auto;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }

    }

  }  
`;

const HorizontalScrollContainer = ({ children, activeElement, menuItems, repositionOnLoad }) => {

  const [ classList, setClassList ] = useState("");
  const [ scrollPosition, setScrollPosition ] = useState(0);

  const wrapper = useRef(null);

  useEffect(() => {
 
    if (repositionOnLoad && activeElement.current && wrapper.current) {

      const { isMediumUp } = screenResolution();
      
      if (isMediumUp === false) {

        const wrapperBounds = wrapper.current.getBoundingClientRect();
        const activeElementBounds = activeElement.current.getBoundingClientRect();      

        if ((activeElementBounds.width + activeElementBounds.x) > (wrapperBounds.width + wrapperBounds.x)) {

          // try to position the current item in the middle of the viewport
          const newScrollPosition = activeElementBounds.width >= wrapperBounds.width ?
            activeElementBounds.x :
            activeElementBounds.x - (wrapperBounds.width / 2) + (activeElementBounds.width / 2);

          setScrollPosition(newScrollPosition);
    
        }

      }

    }

  }, [ activeElement, wrapper ]); 

  useEffect(() => {

    wrapper.current.scroll(scrollPosition, 0);
    setGradientClasses();

  }, [ scrollPosition ]);
  

  const setGradientClasses = () => {
    
    const overflow = hasOverflow(wrapper.current, menuItems.current);

    let classes = "";

    if (overflow.left) {

      classes += "grad-left";
    
    }
    if (overflow.right) {

      classes += " grad-right";
    
    }

    setClassList(classes);
      
  };


  return (
    <ScrollWrapper
      className={classList}
    >
      <div
        ref={wrapper} 
        onScroll={setGradientClasses}   
        className="wrapper"
      >
        {children}
      </div>
    </ScrollWrapper>
  );

};

HorizontalScrollContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  activeElement: PropTypes.oneOfType([
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.object })
  ]).isRequired,
  menuItems: PropTypes.oneOfType([
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.object })
  ]).isRequired,
  repositionOnLoad: PropTypes.bool
};

export default HorizontalScrollContainer;
