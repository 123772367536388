/* eslint-disable react/prop-types */
import BlockContent from "@sanity/block-content-to-react";
import ContactForm from "../components/ContactForm";
import ContentBlockStyles from "../styles/ContentBlockStyles";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { MEDIA_QUERY_MEDIUM } from "../styles/variables";
import PageSubMenu from "../components/PageSubMenu";
import React from "react";
import SEO from "../components/SEO";
import styled from "styled-components";


const SingleViewStyles = styled.div``;
const ColumnViewStyles = styled.div`
  
  @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
    display: grid;
    grid-template-columns: 1fr 400px;
  }

  .cms-content {
    @media (min-width: ${MEDIA_QUERY_MEDIUM}) {
      padding-right: 4rem;
    }
  }

`;

const Page = ({ data, pageContext }) => {
  
  const { page, settings, subPages } = data;

  const PageLayout = page.components?.contactForm ? ColumnViewStyles : SingleViewStyles;

  return (
    <>
      <SEO title={page.metaTitle || page.name} description={page.metaDescription} />
      <Img fluid={page?.image?.asset?.fluid || settings.image.asset.fluid} />
      <ContentBlockStyles>
        <h1 className="h1">{ page.name }</h1>
        <div className="html">
          <PageSubMenu pages={subPages.nodes} currentPageId={pageContext.id} />
          <PageLayout>
            <div className="cms-content"><BlockContent blocks={page._rawContent} /></div>
            { page.components?.contactForm && <ContactForm />}
          </PageLayout>
        </div>
      </ContentBlockStyles>
    </>
  );

};

export default Page;

export const query = graphql`
  query($id: String!, $parentPageId: String!) {
    page: sanityPage(_id: { eq: $id }) {
      name
      components {
        contactForm
        map
      }
      displayLogos
      metaDescription
      metaTitle
      _rawContent
    }
    subPages: allSanityPage (filter: {
      parentPage: {
        _id: { in: [ $id, $parentPageId ]}
      }
    }) {
      nodes {
        name
        _id
        slug {
          current
        }
        order
        parentPage {
          name
          _id
          slug {
            current
          }
        }
      }
    }
    settings: sanitySettings(_id: {eq: "SiteSettings"}) {
      image {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
