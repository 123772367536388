import { MEDIA_QUERY_MEDIUM, MEDIA_QUERY_SMALL_MAX } from "../styles/variables";
import React, { useState } from "react";
import styled from "styled-components";
import useForm, { } from "../hooks/useForm";

function sanitize(string) {

  const map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;",
    "`": "&grave;"
  };
  const reg = /[&<>"'/]/ig;

  return string.replace(reg, match => (map[match]));

}

const SuccessMessage = styled.div`
  color: green;
  background-color: var(--white);
  border-radius: var(--borderRadius);
  padding: 2rem;
`;

const ContactFormStyles = styled.form`

  .success {
    color: green;
  }

  fieldset {
    padding: 0 4rem;
    border: 2px solid var(--themeColour);
    background-color: var(--themeColour);
    color: var(--white);
    border-radius: var(--borderRadius);

    @media (max-width: ${MEDIA_QUERY_SMALL_MAX}) {
      max-width: 400px;
      margin: 4rem auto 0;
    }

    div {
      margin: 2rem 0;
      display: grid;
      grid-gap: 5px;

      &.error {
        color: var(--red);
        background-color: var(--white);
        border-radius: var(--borderRadius);
        padding: 7px 8px;
        font-weight: 500;
        text-align: center;
      }

      &[data-field="subject"] {
        display: none;
      }
    }

    label {
      font-weight: 500;
    }

    input, textarea {
      padding: 7px 8px;
      border: 1px solid transparent;
      border-radius: var(--borderRadius);
    }
    textarea {
      height: 150px;
    }
    button {
      padding: 10px;
      cursor: pointer;
      border: 1px solid transparent;
      border-radius: var(--borderRadius);
    }
  }


`;


const ContactForm = () => {

  const { values, updateValue, resetAllValues } = useForm({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: ""
  });
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ message, setMessage ] = useState(null);

  const handleFormSubmit = async event => {

    event.preventDefault();
    const body = { ...values };

    body.message = sanitize(body.message);

    setLoading(true);
    setError(null);
    setMessage(null);

    try {

      // send this data to a serverless function
      const res = await fetch(`${process.env.GATSBY_SERVERLESS_BASE}/contactFormSubmission`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
      });

      const text = JSON.parse(await res.text());

      setLoading(false);

      // check if everything worked
      if (res.status >= 400 && res.status < 600) {

        setError(text.message);

      } else {

        setMessage("Your message has been sent");
        resetAllValues();

      }

    } catch (error) {

      setLoading(false);
      setError("We could not send your message. Please try again or use another contact option.");
    
    }


  };

  return (
    <ContactFormStyles>
      <fieldset disabled={loading}>

        <div data-field="name">
          <label htmlFor="name">Name</label>
          <input type="text" name="name" value={values.name} onChange={updateValue} />
        </div>
        <div data-field="email">
          <label htmlFor="email">Email</label>
          <input type="email" name="email" value={values.email} onChange={updateValue} />
        </div>
        <div data-field="phone">
          <label htmlFor="phone">Phone number</label>
          <input type="phone" name="phone" value={values.phone} onChange={updateValue} />
        </div>
        <div data-field="subject">
          <label htmlFor="subject">Subject</label>
          <input type="text" name="subject" value={values.subject} onChange={updateValue} />
        </div>
        <div data-field="message">
          <label htmlFor="message">Message</label>
          <textarea name="message" value={values.message} onChange={updateValue}></textarea>
        </div>
        { error ? <div className="error">Error: {error}</div> : ""}
        { message && (
          <SuccessMessage>{ message }</SuccessMessage>
        )}
        <div data-field="submit">
          <button onClick={handleFormSubmit}>Submit</button>
        </div>

      </fieldset>
    </ContactFormStyles>
  );

};

export default ContactForm;
