/**
 * Calculate whether a scroll element has overflow on the left or right-hand side.
 * Assumes a scroll element with a single child, where the child has an unrestricted width.
 * @param scrollElement {HtmlElement}
 * @param scrollElementChild {HTMLElement}
 * @returns {{left: boolean, right: boolean}}
 */
const hasOverflow = (scrollElement, scrollElementChild) => {

  if (!scrollElement || !scrollElementChild) {

    return { left: false, right: false };

  }

  const scrollElementLength = Math.round(scrollElement.scrollLeft + scrollElement.clientWidth);
  const hasOverflowLeft = scrollElement.scrollLeft > 0;
  const hasOverflowRight = scrollElementLength < scrollElementChild.clientWidth;

  return {
    left: hasOverflowLeft,
    right: hasOverflowRight
  };

};

export default hasOverflow;
